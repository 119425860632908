<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
<!--                  <router-link
                      :to="{ name: 'career' }"
                      class="btn btn-standard ml-2"
                  >
                    Back
                  </router-link> --> Applications Received for {{career.title}}
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ <router-link :to="{name:'career'}">
                  Careers</router-link>\ Applications
                </div>
              </div>

            </div>
          </div>
            <div class="card-body">
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table ">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3">Full name</th>
                    <th class="px-3">Email</th>
                    <th class="px-3">Phone</th>
                    <!--                  <th>Job Position</th>-->
                    <th class="pr-3 text-center">Resume download</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-show="applications.length > 0" v-for="(application, index) of applications" :key="index">
                    <td class="px-3">
                      <span class="font-weight-medium">
                        {{application.full_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <a class="text-info mb-1" :href="'mailto:'+application.email">
                        {{application.email ? application.email : 'NA' }}
                      </a>
                     </td>
                    <td class="px-3">
                      <a  class="text-primary mb-1" :href="'tel:'+application.phone">
                        {{application.phone}}
                      </a>
                     </td>
                    <!--                    <td>{{application.career_title}}</td>-->
                    <td class="pr-0 text-center">
                      <span class="cursor-pointer" @click="downloadCv(application.id)">
                        <i class="fas fa-download"></i>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="applications.length == 0">
                    <td colspan="4" class="text-center">
                      No data available to display.
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination v-if="applications.length>0"
                    @input="getApplications"
                    class="pull-right mt-7"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import CareerApplicationService from "@/core/services/career/application/CareerApplicationService";
import CareerService from "@/core/services/career/CareerService";
import { API_URL} from "@/core/config";
const career = new CareerService();
const applicationService = new CareerApplicationService();

export default {
  name: "Career",
  components: {},
  data() {
    return {
      search:{
        career_id:'',
      },
      career:null,
      id:null,
      page: null,
      total: null,
      perPage: null,
      applications: [],
      loading:false,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.search.career_id = this.id;
    this.getApplications();
    this.getCareer();
  },
  methods: {

    getCareer()
    {
      career.show(this.search.career_id).then(response => {
        this.career = response.data.career;

      });
    },
    getApplications() {

      applicationService.paginate(this.search).then(response => {
        this.applications = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    editCareer(id) {
      this.$router.push({
        name: "career-update",
        params: { id: id }
      });
    },

    deleteCareer(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            applicationService
              .delete(id)
              .then(response => {
                this.$snotify.success("Information deleted");

                this.getApplications();
              })
              .catch(error => {
              });
          }
        }
      });
    },   downloadCv(id) {
      let url = API_URL + "user/download/cv/" + id;
      window.open(url)
    }

  }
};
</script>
